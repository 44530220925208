import clsx from 'clsx'
import { CSSProperties, MouseEventHandler, useCallback, useEffect, useState } from 'react'
import type { HeaderResult } from '../data/content/HeaderFragment'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Header.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export type HeaderProps = {
	header: HeaderResult
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()
	const [count, setCount] = useState(0)

	useEffect(() => {
		setOpen(false)
	}, [loading])

	const handleClick: MouseEventHandler<HTMLLIElement> = useCallback((event) => {
		if (event.target instanceof HTMLElement && event.target.closest('a')) {
			setOpen(false)
			setCount((previousCount) => previousCount + 1)
		}
	}, [])

	return (
		<div className={s.Background}>
			<div className={s.Wrapper} key={count}>
				<Container size="wide">
					<div className={s.Main}>
						<Link href="/">
							{props.header.logo && (
								<div className={s.Logo}>
									<ContemberImage image={props.header.logo} layout="intrinsic" />
								</div>
							)}
						</Link>

						<div className={s.Links}>
							{props.header.linkGroups?.items.map((item, i) => (
								<div
									key={item.id}
									className={clsx(s.MenuBox, item.group.length > 0 && s.hasSubmenu)}>
									<Link link={item.link} className={s.Link} activeClassName={s.isActiveLink} />
									<div
										className={clsx(
											s.SubmenuWrapper,
											item.group.length > 1 && s.isMultipleColumns,
											props.header.linkGroups?.items &&
												i === props.header.linkGroups?.items.length - 1 &&
												s.LastItem
										)}>
										{item.group.length > 0 && (
											<div className={s.Submenu}>
												{item.group.map(
													(groupItem) =>
														groupItem.links?.items &&
														groupItem.links?.items.length > 0 && (
															<div key={groupItem.id} className={s.SubmenuSection}>
																<h2 className={s.SubmenuGroupTitle}>{groupItem.links.title}</h2>
																<ul key={item.id} className={s.SubmenuItems}>
																	{groupItem.links?.items.map((item) => (
																		// eslint-disable-next-line
																		<li
																			key={item.id}
																			className={s.SubmenuItem}
																			onClick={handleClick}>
																			<Link
																				link={item.link}
																				activeClassName={s.isActiveLink}
																				className={s.SubmenuLink}>
																				{item.icon && (
																					<span className={s.SubmenuIcon}>
																						<div
																							className={s.SubmenuIcon}
																							style={
																								{
																									'--Header-SubmenuIcon-width': item.icon.width,
																									'--Header-SubmenuIcon-height': item.icon.height,
																								} as CSSProperties
																							}>
																							<ContemberImage
																								image={item.icon}
																								layout="fill"
																								objectFit="cover"
																							/>
																						</div>
																					</span>
																				)}
																				<div className={s.SubmenuTexts}>
																					<h4 className={s.SubmenuTitle}>{item.link?.title}</h4>
																					{item.description && (
																						<p className={s.SubmenuText}>{item.description}</p>
																					)}
																				</div>
																			</Link>
																		</li>
																	))}
																</ul>
															</div>
														)
												)}
											</div>
										)}
									</div>
								</div>
							))}
						</div>
						<Link className={clsx(s.SpecialLink, s.isDesktop)} link={props.header.specialLink} />

						<button
							type="button"
							className={clsx(s.IconHamburger, s.is_hamburger)}
							onClick={() => setOpen((old) => !old)}
							aria-label="hamburger menu">
							<span className={s.IconHamburgerIcon}></span>
						</button>
						<button
							className={clsx(s.IconHamburger, s.Close, open && s.isActive)}
							onClick={() => setOpen((old) => !old)}>
							<Icon name="cross" />
						</button>
						<div className={clsx(s.MobileHeader, open && s.isActive)}>
							<div className={s.LogoMobile}>
								<Link href="/" className={s.LogoLink}>
									{props.header.logo && (
										<ContemberImage image={props.header.logo} layout="intrinsic" />
									)}
								</Link>
							</div>
							<div className={s.MobileSections}>
								{props.header.linkGroups?.items.map((item) => (
									<div key={item.id} className={s.MobileSection}>
										<div className={s.MobileLink}>
											<Link link={item.link} className={s.Link} activeClassName={s.isActiveLink}>
												<span className={s.LinkTitle}>{item.link?.title}</span>
												<span className={s.LinkArrow}>
													<Icon name="arrow" />
												</span>
											</Link>
										</div>
										{item.group.length > 0 && (
											<div className={s.MobileSectionIn}>
												{item.group.map((groupItem) => (
													<div key={groupItem.id} className={s.MobileColumn}>
														<h2 className={s.SubmenuGroupTitle}>{groupItem.links?.title}</h2>
														<ul className={s.SubmenuItems}>
															{groupItem.links?.items.map((item) => (
																// eslint-disable-next-line
																<li key={item.id} className={s.SubmenuItem} onClick={handleClick}>
																	<Link
																		link={item.link}
																		className={s.SubmenuLink}
																		activeClassName={s.isActiveLink}>
																		{item.icon && (
																			<div className={s.SubmenuIcon}>
																				<ContemberImage image={item.icon} objectFit="contain" />
																			</div>
																		)}
																		{item.link?.title}
																	</Link>
																</li>
															))}
														</ul>
													</div>
												))}
											</div>
										)}
									</div>
								))}
							</div>
							<div className={clsx(s.Links, s.MobileLinks, open && s.isActive)}>
								<a href={props.header.specialLink?.externalLink} data-beyond className={s.Link}>
									{props.header.specialLink?.title}
								</a>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</div>
	)
}
