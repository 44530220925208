import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent, ReactNode } from 'react'
import type { ContentReferenceType } from '../../generated/content'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import type { ContentResult } from '../data/content/ContentFragment'
import { useContentRendererCopyPasteBugWorkaround } from '../utils/useContentRendererCopyPasteBugWorkaround'
import { AsideContent } from './AsideContent'
import { Banner } from './Banner'
import { BoxList } from './BoxList'
import styles from './ContentRenderer.module.sass'
import { Gallery } from './Gallery'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	isArticle?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	asideContent({ reference }) {
		return (
			reference.primaryText &&
			reference.alignment &&
			reference.contentType && (
				<AsideContent
					title={reference.primaryText}
					text={reference.secondaryText}
					textList={reference.textList}
					mediumList={reference.media}
					alignmentReverse={reference.alignment}
					contentType={reference.contentType}
					otherBackgorundColor={reference.otherBackgroundColor}
					secondaryButton={reference.secondaryButton}
					buttonDescription={reference.buttonDescription}
					formPlaceholder={reference.formPlaceholder}
					formGdprLabel={reference.formGdprLabel}
				/>
			)
		)
	},
	boxes({ reference }) {
		return (
			reference.boxList && <BoxList boxList={reference.boxList} button={reference.primaryButton} />
		)
	},
	banner({ reference }) {
		return (
			reference.primaryText &&
			reference.image &&
			reference.textList && (
				<Banner
					title={reference.primaryText}
					//mediumList={reference.media}
					image={reference.image}
					textList={reference.textList}
					button={reference.primaryButton}
				/>
			)
		)
	},
	gallery({ reference }) {
		return reference.media && <Gallery mediumList={reference.media} />
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	isArticle,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<RichTextRenderer
				blocks={blocks}
				sourceField="json"
				referenceRenderers={referenceRenderers}
				renderElement={(element) => {
					const { type } = element.element
					if (nestedTypes.includes(type)) {
						return element.fallback
					}
					if (standaloneTypes.includes(type)) {
						return (
							<div>
								{type !== 'reference' ||
								!element.referenceType ||
								element.referenceType in referenceRenderers ? (
									element.fallback
								) : (
									<div className={styles.notImplemented}>
										<div className={styles.notImplemented_name}>{element.referenceType}</div>
										is not yet implemented
									</div>
								)}
							</div>
						)
					}
					return <Wysiwyg isArticle={isArticle}>{element.fallback}</Wysiwyg>
				}}
			/>
		</div>
	)
}
