import type { FeatureResult } from '../data/content/FeatureFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './Feature.module.sass'
import { Medium } from './Medium'

export type FeatureProps = {
	feature: FeatureResult
}

export function Feature(props: FeatureProps) {
	return (
		<div className={s.Feature}>
			<Container size="wide">
				<div className={s.Wrapper}>
					{props.feature.icon && (
						<div className={s.Icon}>
							<ContemberImage image={props.feature.icon} />
						</div>
					)}
					<h1 className={s.Title}>{props.feature.title}</h1>
					<p className={s.LeadParagraph}>{props.feature.leadParagraph}</p>
					{props.feature.medium && (
						<div className={s.Medium}>
							<Medium medium={props.feature.medium} />
						</div>
					)}
				</div>
			</Container>
			{props.feature.content && <ContentRenderer content={props.feature.content} />}
		</div>
	)
}
