import type { LightboxSource } from '@mangoweb/fslightbox'
import { useLightbox } from '@mangoweb/react-fslightbox'
import type { FunctionComponent } from 'react'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Gallery.module.sass'
import { Icon } from './Icon'

export type GalleryProps = {
	mediumList: MediumListResult
}

export const Gallery: FunctionComponent<GalleryProps> = ({ mediumList }) => {
	const items = mediumList.items
		.map((item) => {
			if (item.medium) {
				const source = item.medium.video?.src ?? item.medium.image?.url
				if (source) {
					const result: LightboxSource = {
						source,
						type: item.medium.video ? 'video' : 'image',
					}
					return result
				}
			}
		})
		.filter(filterNonEmpty)

	const lightbox = useLightbox(items, {
		onSlideActivate: (current, previous) => {
			const getSlideVideo = (slideIndex: number) => {
				const video = document.querySelector(
					`.fslightbox-absoluted.fslightbox-full-dimension.fslightbox-flex-centered:nth-child(${
						slideIndex + 1
					}) video`
				)
				if (video instanceof HTMLVideoElement) {
					return video
				}
			}

			requestAnimationFrame(() => {
				if (previous !== null) {
					getSlideVideo(previous.slideIndex)?.pause()
				}
				getSlideVideo(current.slideIndex)?.play()
			})
		},
	})
	return (
		<Container size="wide" disableGutters>
			<div className={s.Gallery}>
				{mediumList?.items.map((item, i) => (
					<div key={item.id} className={s.ItemWrapper}>
						<div className={s.Item}>
							{item.medium?.type === 'image' && item.medium.image && (
								<div className={s.CarouselWrapper}>
									<div className={s.Image}>
										<button className={s.Button} onClick={() => lightbox?.open(i)}>
											<ContemberImage image={item.medium.image} layout="fill" objectFit="cover" />
										</button>
									</div>
								</div>
							)}
							{item.medium?.type === 'video' && item.medium.video && (
								<div className={s.ImageWrapper}>
									<div className={s.Play}>
										<Icon name="play" />
									</div>
									<div className={s.CarouselWrapper}>
										<div className={s.Image}>
											<button className={s.Button} onClick={() => lightbox?.open(i)}>
												<video
													className={s.VideoIn}
													loop
													muted
													playsInline
													poster={item.medium.video?.poster?.url}>
													<source src={item.medium.video?.src} type={item.medium.video?.type} />
												</video>
											</button>
										</div>
									</div>
								</div>
							)}
							<p className={s.Alt}>{item.medium?.image?.alt ?? item.medium?.video?.videoInfo}</p>
						</div>
					</div>
				))}
				<span className={s.LastPlaceholder}></span>
			</div>
		</Container>
	)
}
