import s from './FlashMessage.module.sass'

type FlashMessageProps = {
	onDissmiss?: () => void
}

export const FlashMessage = (props: FlashMessageProps) => {
	const { onDissmiss } = props

	return (
		<div className={s.FlashMessage} onAnimationEnd={onDissmiss}>
			<p>Díky za zájem, zůstáváme v kontaktu.</p>
		</div>
	)
}
