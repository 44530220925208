import Link from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import type { LinkResult } from '../data/content/LinkFragment'

export const ContemberLink = React.memo(function ContemberLink(props: {
	link: LinkResult | undefined | null
	children?: React.ReactNode
	className?: string
}) {
	const { type, internalLink, externalLink } = props.link ?? {}

	const { href, ...attrs } = React.useMemo(() => {
		const attrs: Partial<
			React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
		> = {}

		switch (type) {
			case LinkType.internal:
				return { ...attrs, href: internalLink?.url ?? '#' }
			case LinkType.external:
			default:
				return { ...attrs, href: externalLink ?? '#' }
		}
	}, [externalLink, internalLink?.url, type])

	if (!props.link || href === null) {
		return null
	}

	return (
		<Link href={href}>
			<a {...attrs} className={props.className}>
				{props.children ?? props.link.title}
			</a>
		</Link>
	)
})
