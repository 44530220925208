import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { ButtonResult } from '../data/content/ButtonFragment'
import type { ImageResult } from '../data/content/ImageFragment'
import type { TextListResult } from '../data/content/TextListFragment'
import s from './Banner.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

export type BannerProps = {
	title: string
	//mediumList?: MediumListResult
	image: ImageResult
	textList?: TextListResult
	button?: ButtonResult
}

export const Banner: FunctionComponent<BannerProps> = ({
	title,
	//mediumList,
	image,
	textList,
	button,
}) => {
	const textListHalfIndex = textList?.items.length
		? Math.floor(textList?.items.length / 2)
		: undefined
	const firstTextPartMobile = textList?.items.slice(0, textListHalfIndex)
	const secondTextPartMobile = textList?.items.slice(textListHalfIndex, textList.items.length)

	const chunkSize = textList?.items.length && Math.floor(textList?.items.length / 3)

	const firstTextPartDesktop = textList?.items.slice(0, chunkSize)
	const secondTextPartDesktop = chunkSize && textList?.items.slice(chunkSize, 2 * chunkSize)
	const thirdTextPartDesktop =
		chunkSize && textList?.items.slice(2 * chunkSize, textList.items.length)

	return (
		<Container size="fullWidth">
			<div className={s.Banner}>
				<div className={s.Image}>
					<ContemberImage image={image} layout="fill" objectFit="cover" />
				</div>
				<div className={s.Box}>
					<Container size="wide">
						<h3 className={s.Title}>
							<RichTextRenderer source={title} />
						</h3>
						<div className={s.Content}>
							{/* <div className={s.Column}>
								{mediumList?.items.map((item) => (
									<div key={item.id}>
										{item.medium?.type === 'image' && item.medium.image && (
											<ContemberImage image={item.medium.image} layout="fill" objectFit="contain" />
										)}
									</div>
								))}
							</div> */}
							<div className={s.Texts}>
								<>
									<div className={s.firstTextPartDesktop}>
										{firstTextPartDesktop?.map((item) => {
											return (
												<div key={item.id}>
													<div className={s.Line}></div>
													<h4 className={s.BoxTitle}>
														{item.title && <RichTextRenderer source={item.title} />}
													</h4>
													<p className={s.BoxText}>
														{item.text && <RichTextRenderer source={item.text} />}
													</p>
												</div>
											)
										})}
									</div>
									<div className={s.firstTextPartMobile}>
										{firstTextPartMobile?.map((item) => {
											return (
												<div key={item.id}>
													<div className={s.Line}></div>
													<h4 className={s.BoxTitle}>
														{item.title && <RichTextRenderer source={item.title} />}
													</h4>
													<p className={s.BoxText}>
														{item.text && <RichTextRenderer source={item.text} />}
													</p>
												</div>
											)
										})}
									</div>
								</>
								<>
									<div className={s.secondTextPartDesktop}>
										{secondTextPartDesktop &&
											secondTextPartDesktop?.map((item) => {
												return (
													<div key={item.id}>
														<div className={s.Line}></div>
														<h4 className={s.BoxTitle}>
															{item.title && <RichTextRenderer source={item.title} />}
														</h4>
														<p className={s.BoxText}>
															{item.text && <RichTextRenderer source={item.text} />}
														</p>
													</div>
												)
											})}
									</div>
									<div className={s.secondTextPartMobile}>
										{secondTextPartMobile?.map((item) => {
											return (
												<div key={item.id}>
													<div className={s.Line}></div>
													<h4 className={s.BoxTitle}>
														{item.title && <RichTextRenderer source={item.title} />}
													</h4>
													<p className={s.BoxText}>
														{item.text && <RichTextRenderer source={item.text} />}
													</p>
												</div>
											)
										})}
									</div>
								</>
								<div className={s.thirdTextPartDesktop}>
									{thirdTextPartDesktop &&
										thirdTextPartDesktop?.map((item) => {
											return (
												<div key={item.id}>
													<div className={s.Line}></div>
													<h4 className={s.BoxTitle}>
														{item.title && <RichTextRenderer source={item.title} />}
													</h4>
													<p className={s.BoxText}>
														{item.text && <RichTextRenderer source={item.text} />}
													</p>
												</div>
											)
										})}
								</div>
							</div>
						</div>
						{button?.link?.title && (
							<div className={s.Button}>
								<Button button={button} inverted />
							</div>
						)}
					</Container>
				</div>
			</div>
		</Container>
	)
}
