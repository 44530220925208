import * as z from 'zod'
import { string } from 'zod'

export const NewsletterFormInput = () =>
	z.object({
		email: string().email(),
		gdpr: string(),
	})

export type NewsletterFormInputData = z.infer<ReturnType<typeof NewsletterFormInput>>
