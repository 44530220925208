import { RichTextRenderer } from '@contember/react-client'
import { Fragment, FunctionComponent } from 'react'
import type { BoxListResult } from '../data/content/BoxListFragment'
import type { ButtonResult } from '../data/content/ButtonFragment'
import s from './BoxList.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'

export type BoxListProps = {
	boxList: BoxListResult
	button?: ButtonResult
}

export const BoxList: FunctionComponent<BoxListProps> = ({ boxList, button }) => {
	return (
		<Container size="normal">
			<div className={s.BoxList}>
				{boxList.items.map((box) => (
					<Fragment key={box.id}>
						{box.type === 'text' && (
							<Fragment key={box.id}>
								<div className={s.Box}>
									<div className={s.IconTitle}>
										{box.icon?.image && (
											<div className={s.Icon}>
												<ContemberImage image={box.icon?.image} layout="intrinsic" />
											</div>
										)}
										<h4 className={s.Title}>{box.title}</h4>
									</div>
									<div className={s.TextBox}>
										{box.textList?.items.map(
											(item) =>
												item.text && (
													<div key={item.id} className={s.Text}>
														<span className={s.Arrow}>
															<Icon name="arrow" />
														</span>
														<RichTextRenderer source={item.text} />
													</div>
												)
										)}
									</div>
								</div>
							</Fragment>
						)}
						{box.type === 'image' && (
							<div key={box.id} className={s.BoxWrapper}>
								<div className={s.Box}>
									{box.image && (
										<div className={s.Image}>
											<ContemberImage image={box.image} layout="fill" objectFit="cover" />
										</div>
									)}
								</div>
							</div>
						)}
					</Fragment>
				))}
			</div>
			<div className={s.Button}>{button?.link?.title && <Button button={button} />}</div>
		</Container>
	)
}
