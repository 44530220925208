import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { CSSProperties, FunctionComponent, useState } from 'react'
import type { ContentAlignment, ContentType } from '../../generated/content'
import type { ButtonResult } from '../data/content/ButtonFragment'
import type { MediumListResult } from '../data/content/MediumListFragment'
import type { TextListResult } from '../data/content/TextListFragment'
import s from './AsideContent.module.sass'
import { Button } from './Button'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { NewsletterForm } from './NewsletterForm'

export type AsideContentProps = {
	title: string
	text?: string
	textList?: TextListResult
	mediumList?: MediumListResult
	alignmentReverse: ContentAlignment
	contentType: ContentType
	otherBackgorundColor?: string
	secondaryButton?: ButtonResult
	buttonDescription?: string
	formPlaceholder?: string
	formGdprLabel?: string
}

export const AsideContent: FunctionComponent<AsideContentProps> = ({
	title,
	text,
	textList,
	mediumList,
	alignmentReverse,
	contentType,
	otherBackgorundColor,
	buttonDescription,
	formPlaceholder,
	formGdprLabel,

	secondaryButton,
}) => {
	const [showForm, setShowForm] = useState(false)

	return (
		<div
			className={s.Wrapper}
			style={{ '--AsideContent-otherBackground': otherBackgorundColor } as CSSProperties}>
			<Container size="wide">
				<div className={clsx(s.AsideContent, s[`isText_${alignmentReverse}`])}>
					<div className={s.Box}>
						<div className={s.Content}>
							<h3 className={s.Title}>
								<RichTextRenderer source={title} />
							</h3>
							{text && contentType === 'text' && (
								<div className={s.Text}>
									<RichTextRenderer source={text} />
								</div>
							)}
							{contentType === 'list' && (
								<div className={s.List}>
									{textList?.items.map((item) => (
										<div key={item.id} className={s.ListItem}>
											<div className={s.Dot}></div>
											<p className={s.ListText}>
												{item.text && <RichTextRenderer source={item.text} />}
											</p>
										</div>
									))}
								</div>
							)}
							{buttonDescription && (
								<button
									className={clsx(s.Button, showForm && s.hideButton)}
									onClick={() => setShowForm(true)}>
									{buttonDescription}

									<span className={s.Arrow}>
										<Icon name="buttonArrow" />
									</span>
								</button>
							)}

							{showForm && (
								<div className={s.Form}>
									<NewsletterForm darkForm placeholder={formPlaceholder} gdpr={formGdprLabel} />
								</div>
							)}
							{secondaryButton?.link?.title && <Button button={secondaryButton} />}
						</div>
					</div>
					{mediumList?.items.map((item) => (
						<div
							key={item.id}
							className={clsx(s.Box, s.Image, otherBackgorundColor && s.OtherBackgroundImage)}>
							{item.medium?.type === 'image' && item.medium.image && (
								<ContemberImage image={item.medium.image} layout="intrinsic" />
							)}
							{item.medium?.type === 'video' && item.medium.video && (
								// <div className={s.Video}>
								<video
									className={s.VideoIn}
									autoPlay
									loop
									muted
									playsInline
									poster={item.medium.video.poster?.url}>
									<source src={item.medium.video.src} type={item.medium.video.type} />
								</video>
								// </div>
							)}
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}
