import clsx from 'clsx'
import { useState } from 'react'
import type { HomePageResult } from '../data/content/HomePageFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './HomePage.module.sass'
import { Icon } from './Icon'
import { NewsletterForm } from './NewsletterForm'

export type HomeProps = {
	homePage: HomePageResult
	isSplashPage?: boolean
}

export function HomePage(props: HomeProps) {
	const [showForm, setShowForm] = useState(false)
	return (
		<div className={s.HomePage}>
			<div className={clsx(s.Background, props.isSplashPage && s.isSplashPage)}></div>
			<Container size="wide">
				<div className={clsx(s.Cover, props.isSplashPage && s.isSplashPage)}>
					{props.homePage.logo && (
						<div className={s.Logo}>
							<ContemberImage image={props.homePage.logo} layout="intrinsic" />
						</div>
					)}
					<div className={s.LeadParagraph}>{props.homePage.leadParagraph}</div>
					{props.homePage.image && (
						<div className={s.HeroCover}>
							<ContemberImage image={props.homePage.image} layout="fill" objectFit="contain" />
						</div>
					)}
					{props.homePage.video?.src && (
						<video autoPlay playsInline muted loop className={s.HeroCover}>
							<source src={props.homePage.video.src} />
						</video>
					)}
					{props.homePage.buttonDescription && (
						<button
							className={clsx(
								s.Button,
								showForm && s.hideButton,
								props.isSplashPage && s.splashHideButton
							)}
							onClick={() => setShowForm(true)}>
							{props.homePage.buttonDescription}
							<span className={s.Arrow}>
								<Icon name="arrow" />
							</span>
						</button>
					)}
					{showForm && (
						<div className={s.Form}>
							<NewsletterForm
								placeholder={props.homePage.formPlaceholder}
								gdpr={props.homePage.formGdprLabel}
							/>
						</div>
					)}
					<a
						href="#content"
						className={clsx(s.ArrowDown, s.Bounce, props.isSplashPage && s.ArrowDownSplashPage)}>
						<Icon name="arrowDown" />
					</a>
				</div>
			</Container>
			<span id="content">
				{!props.isSplashPage && props.homePage.content && (
					<ContentRenderer content={props.homePage.content} />
				)}
			</span>
		</div>
	)
}
