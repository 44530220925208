import { RichTextRenderer } from '@contember/react-client'
import type { FooterResult } from '../data/content/FooterFragment'
import { Container } from './Container'
import s from './Footer.module.sass'

export type FooterProps = {
	footer: FooterResult
}

export function Footer(props: FooterProps) {
	return (
		<Container size="fullWidth">
			<div className={s.Footer}>
				{/* <div className={s.Groups}>
					{props.footer.linkGroups?.items.map((item) => (
						<div key={item.id} className={s.Group}>
							{item.links?.items.map((item) => (
								<div key={item.id}>
									<Link link={item.link} className={s.Link}>
										<p className={s.Title}>{item.link?.title}</p>
										<p className={s.AdditionalInfo}>{item.additionalInfo}</p>
									</Link>
								</div>
							))}
						</div>
					))}
				</div>
				{props.footer.logoFirst && (
					<div style={{ maxWidth: (props.footer.logoFirst.width ?? 0) / 2 }}>
					<ContemberImage image={props.footer.logoFirst} layout="intrinsic" />
					</div>
					)}
					<p className={s.By}>by</p>
					{props.footer.logoSecond && (
						<div
						style={{ maxWidth: (props.footer.logoSecond.width ?? 0) / 2 }}
						className={s.LogoSecond}>
						<ContemberImage image={props.footer.logoSecond} />
						</div>
					)} */}

				<div className={s.Box}>
					{props.footer.textList?.items.map((item) => (
						<div key={item.id} className={s.BoxItem}>
							<p className={s.BoxText}>{item.text && <RichTextRenderer source={item.text} />}</p>
						</div>
					))}
				</div>
			</div>
		</Container>
	)
}
