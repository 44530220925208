import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { InfiniteArticles } from '../app/components/Article'
import { Feature } from '../app/components/Feature'
import { Footer } from '../app/components/Footer'
import { GenericPage } from '../app/components/GenericPage'
import { Header } from '../app/components/Header'
import { HomePage } from '../app/components/HomePage'
import { combineUrl } from '../app/data/combineUrl'
import { ArticleFragment } from '../app/data/content/ArticleFragment'
import { ArticleGenericInfoFragment } from '../app/data/content/ArticleGenericInfoFragment'
import { FeatureFragment } from '../app/data/content/FeatureFragment'
import { FooterFragment } from '../app/data/content/FooterFragment'
import { GenericPageFragment } from '../app/data/content/GenericPageFragment'
import { HeaderFragment } from '../app/data/content/HeaderFragment'
import { HomePageFragment } from '../app/data/content/HomePageFragment'
import { One, OrderDirection, Thunder } from '../generated/content'
import { RespondWithNotFound, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const header = props.dataFromContember.getHeader
	const { article, feature, genericPage, homePage } = props.dataFromContember.getLinkable ?? {}
	const footer = props.dataFromContember.getFooter
	const articleGenericInfo = props.dataFromContember.getArticleGenericInfo
	return (
		<>
			<Head>
				<title>Roboton</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>

			{header && <Header header={header} />}
			{article && articleGenericInfo && (
				<InfiniteArticles
					article={article}
					genericInfo={articleGenericInfo}
					firstArticleUrl={props.firstArticleUrl}
					nextPageUrls={props.nextPageUrls}
				/>
			)}
			{feature && <Feature feature={feature} />}
			{genericPage && <GenericPage genericPage={genericPage} />}
			{homePage && <HomePage homePage={homePage} />}
			{footer && <Footer footer={footer} />}

			{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const dataFromContember = await content.query({
		// ...SiteFragment(urlInfo.locale),
		getHomePage: [{ by: { unique: One.One } }, HomePageFragment()],
		getHeader: [
			{
				by: {
					unique: One.One,
				},
			},
			HeaderFragment(),
		],
		getArticleGenericInfo: [
			{
				by: {
					unique: One.One,
				},
			},
			ArticleGenericInfoFragment(),
		],
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				homePage: [{}, HomePageFragment()],
				genericPage: [{}, GenericPageFragment()],
				article: [{}, ArticleFragment()],
				feature: [{}, FeatureFragment()],
			},
		],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			FooterFragment(),
		],
	})
	const nextPageUrls: string[] = []
	let firstArticleUrl: string | null = null

	if (dataFromContember.getLinkable?.article) {
		const nextArticle = await content.query({
			__alias: {
				firstArticleUrl: {
					listArticle: [
						{
							limit: 1,
							orderBy: [{ createdAt: OrderDirection.desc }],
						},
						{
							link: [
								{},
								{
									url: true,
								},
							],
						},
					],
				},
			},
			listArticle: [
				{
					limit: 2,
					orderBy: [{ createdAt: OrderDirection.desc }],
					filter: {
						id: { notEq: dataFromContember.getLinkable?.article.id },
						createdAt: {
							lt: dataFromContember.getLinkable.article.createdAt,
						},
					},
				},
				{
					link: [
						{},
						{
							url: true,
						},
					],
				},
			],
		})

		firstArticleUrl = nextArticle.firstArticleUrl.listArticle[0]?.link?.url ?? null
		const first = nextArticle.listArticle[0]?.link?.url ?? null
		const second = nextArticle.listArticle[1]?.link?.url ?? null

		if (first) {
			nextPageUrls.push(first)
		}
		if (second) {
			nextPageUrls.push(second)
		}
	}

	if (
		!dataFromContember.getLinkable?.homePage &&
		!dataFromContember.getLinkable?.genericPage &&
		!dataFromContember.getLinkable?.article &&
		!dataFromContember.getLinkable?.feature
	) {
		throw new RespondWithNotFound('Blog post not found')
	}

	return {
		props: {
			url,
			urlInfo,
			dataFromContember,
			serverTime: new Date().toString(),
			firstArticleUrl,
			nextPageUrls,
		},
	}
})
