import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import s from './ActiveUrlInView.module.sass'

const ActiveUrlInView: FunctionComponent<{
	url: string
	children: React.ReactNode
	className?: string
}> = ({ url, children, className }) => {
	const [ref, inView] = useInView({
		rootMargin: '-25% 0px -75% 0px',
	})

	const router = useRouter()
	const routerRef = useRef(router)

	routerRef.current = router

	useEffect(() => {
		if (inView) {
			routerRef.current.replace(url, undefined, { shallow: true })
		}
	}, [inView, url])

	return (
		<div ref={ref} className={clsx(className, s.ArticleInView)}>
			{children}
		</div>
	)
}

export default ActiveUrlInView
