/* eslint-disable jsx-a11y/no-autofocus */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import { NewsletterFormInput } from '../forms/NewsletterSubscription/NewsletterInput'
import { validateZod } from '../validators/utils/validateZod'
import { FlashMessage } from './FlashMessage'
import { Icon } from './Icon'
import s from './NewsletterForm.module.sass'

export const api = createFetcher<API>('/api/v1/[handler]')

export type NewsletterFormProps = {
	darkForm?: boolean
	placeholder?: string
	gdpr?: string
}

export function NewsletterForm(props: NewsletterFormProps) {
	const mutation = useMutation(async (formData: FormData) => {
		const data = Object.fromEntries(formData.entries())

		const validatedData = validateZod(data, NewsletterFormInput())
		return api('newsletterForm', validatedData)
	})
	const [showFlashMessage, setShowFlashMessage] = useState(false)

	useEffect(() => {
		setShowFlashMessage(mutation.isSuccess)
	}, [mutation.isSuccess])

	return (
		<>
			<form
				className={clsx(s.Form, props.darkForm && s.isDark)}
				onSubmit={(e) => {
					e.preventDefault()
					mutation.mutate(new FormData(e.currentTarget))
				}}>
				<div className={s.InputsWrapper}>
					<input
						type="email"
						name="email"
						placeholder={props.placeholder ?? 'Zadejte E-mail:'}
						className={s.Input}
						autoFocus
					/>
					<button className={s.OkBtn} type="submit">
						OK
						<span className={s.Icon}>
							<Icon name="arrow" />
						</span>
					</button>
				</div>
				<label className={s.Gdpr}>
					<input type="checkbox" name="gdpr" required />
					{props.gdpr && (
						<span className={s.GdprLabel}>
							<RichTextRenderer source={props.gdpr} />
						</span>
					)}
				</label>
			</form>
			{showFlashMessage && <FlashMessage onDissmiss={() => setShowFlashMessage(false)} />}
		</>
	)
}
