import type { GenericPageResult } from '../data/content/GenericPageFragment'
import { ContentRenderer } from './ContentRenderer'
import s from './GenericPage.module.sass'

export type GenericProps = {
	genericPage: GenericPageResult
	isSplashPage?: boolean
}

export function GenericPage(props: GenericProps) {
	return (
		<div className={s.GenericPage}>
			{!props.isSplashPage && props.genericPage.content && (
				<ContentRenderer content={props.genericPage.content} />
			)}
		</div>
	)
}
