import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Container } from './Container'
import style from './Wysiwyg.module.sass'

export type WysiwygProps = {
	source?: string
	isArticle?: boolean
}

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({
	source,
	children,
	isArticle = false,
}) => {
	return (
		<Container size="wide" isArticle={isArticle}>
			<div className={clsx(style.wrapper, isArticle && style.isArticle)}>
				{source ? <RichTextRenderer source={source} /> : children}
			</div>
		</Container>
	)
}
