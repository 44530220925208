import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { ButtonResult } from '../data/content/ButtonFragment'
import s from './Button.module.sass'
import { ContemberLink } from './ContemberLink'
import { Icon } from './Icon'

export type ButtonProps = {
	button: ButtonResult
	inverted?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({ button, inverted }) => {
	return (
		<div
			className={clsx(
				s.Button,
				inverted && s.Inverted,
				button.type === 'primary' && s.PrimaryButton,
				button.type === 'secondary' && s.SecondaryButton
			)}>
			<ContemberLink link={button.link} />
			<div className={s.Arrow}>
				<Icon name="buttonArrow" />
			</div>
		</div>
	)
}
