import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

export type PaginatedUrl = string

export type NextPageInViewProps = {
	nextUrl: PaginatedUrl
	children?: React.ReactNode
	keepPreviousUrl?: boolean
}

const NextPageInViewInner: FunctionComponent<NextPageInViewProps> = ({
	nextUrl,
	children,
	keepPreviousUrl,
}) => {
	const router = useRouter()

	const routerRef = useRef(router)

	const [ref, inView] = useInView({
		triggerOnce: true,
		rootMargin: '0px 0px',
	})

	const pageString = JSON.stringify(routerRef.current.query.page)

	useEffect(() => {
		if (inView) {
			const oldPath = routerRef.current.asPath

			routerRef.current
				.replace(nextUrl, undefined, {
					scroll: false,
				})
				.then(() => {
					if (keepPreviousUrl) {
						routerRef.current.replace(oldPath, undefined, {
							scroll: false,
							shallow: true,
						})
					}
				})
		}
	}, [inView, pageString, keepPreviousUrl, nextUrl])

	return (
		<div ref={ref} style={{ minHeight: 1 }}>
			{children}
		</div>
	)
}

export const NextPageInView: FunctionComponent<NextPageInViewProps> = (props) => {
	return <NextPageInViewInner key={JSON.stringify([props.nextUrl])} {...props} />
}
