import type { MediumResult } from '../data/content/MediumFragment'
import { ContemberImage } from './ContemberImage'
import s from './Medium.module.sass'

export type MediumProps = {
	medium: MediumResult
}

export function Medium(props: MediumProps) {
	return (
		<div className={s.Wrapper}>
			{props.medium.video && (
				<div className={s.Video}>
					<video
						className={s.VideoIn}
						autoPlay
						loop
						muted
						playsInline
						poster={props.medium.video.poster?.url}>
						<source src={props.medium.video.src} type={props.medium.video.type} />
					</video>
				</div>
			)}
			{props.medium.image && (
				<div className={s.Image}>
					<ContemberImage image={props.medium.image} objectFit="contain" layout="intrinsic" />
				</div>
			)}
		</div>
	)
}
