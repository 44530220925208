import { useState } from 'react'
import type { ArticleResult } from '../data/content/ArticleFragment'
import type { ArticleGenericInfoResult } from '../data/content/ArticleGenericInfoFragment'
import { NextPageInView } from '../utils/paginated/NexPageInView'
import { useKeepOld } from '../utils/paginated/usePaginated'
import ActiveUrlInView from './ActiveUrlInView'
import s from './Article.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContemberLink } from './ContemberLink'
import { ContentRenderer } from './ContentRenderer'
import { Icon } from './Icon'

export type ArticleProps = {
	article: ArticleResult
	genericInfo?: ArticleGenericInfoResult
	firstArticleUrl: string | null
	nextPageUrls: string[]
}

export function Article(props: ArticleProps) {
	return (
		<div className={s.Article}>
			<Container size="article">
				<p className={s.Title}>{props.article.title}</p>
				{props.article.image && (
					<div className={s.Image}>
						<ContemberImage image={props.article.image} />
					</div>
				)}
				<p className={s.Date}>
					{new Intl.DateTimeFormat('cs', {
						day: 'numeric',
						month: 'numeric',
						year: 'numeric',
					}).format(new Date(props.article.createdAt))}
				</p>
				<p className={s.LeadParagraph}>{props.article.leadParagraph}</p>
			</Container>
			<Container size="article" disableGutters>
				{props.article.content && <ContentRenderer isArticle content={props.article.content} />}
			</Container>
			<div className={s.ArticleNewsletterWrapper}>
				<div className={s.ArticleNewsletter}>
					<p>{props.genericInfo?.contactLabelText}</p>
					<div className={s.Button}>
						<ContemberLink className={s.Link} link={props.genericInfo?.contactLink}>
							{props.genericInfo?.contactLink?.title}
							<div className={s.Arrow}>
								<Icon name="buttonArrow" />
							</div>
						</ContemberLink>
					</div>
				</div>
			</div>
		</div>
	)
}

export function InfiniteArticles(props: ArticleProps) {
	const [firstUrl] = useState(props.article.link?.url)
	const { items } = useKeepOld({ id: props.article.id, ...props })

	const nextPageUrl =
		items.length === 1 && props.firstArticleUrl !== firstUrl
			? props.firstArticleUrl
			: props.nextPageUrls.filter((url) => url !== firstUrl)?.[0]

	return (
		<>
			{items.map((article) => (
				<ActiveUrlInView key={article.id} url={article.article.link?.url ?? ''}>
					<Article {...article} />
				</ActiveUrlInView>
			))}
			{nextPageUrl && <NextPageInView nextUrl={nextPageUrl} keepPreviousUrl />}
		</>
	)
}
